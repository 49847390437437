<template>
  <div class="user-container">
    <el-input
      class="search-input"
      placeholder="Family_id/User_id/Phone/Email/..."
      prefix-icon="el-icon-search"
      @keyup.enter.native="searchClick"
      v-model="searchValue">
      <el-button slot="append" type="primary" @click="searchClick">Search</el-button>
    </el-input>
    <div class="flexview">
      <div class="user-msg">
        <div class="avatar">
          <el-avatar class="photo" shape="circle" :size="50" :src="userList.photo" :style="{border:'3px solid ' + userList.color}"></el-avatar>
          <h4>{{userList.full_name || 'Not Set'}}</h4>
        </div>
        <div>User_id:<br/>{{userList.user_id}}</div>
        <div>Register time:<br/>{{userList.register_time}}</div>
        <div>Location:<br/>{{userList.location || 'Not Set'}}</div>
        <div>Phone:<br/>{{userList.phone || 'Not Set'}}</div>
        <div>Email:<br/>{{userList.email || 'Not Set'}}</div>
        <div>DoB:<br/>{{userList.dob}}</div>
        <div class="modify">
          Modify VIP time<br/>
          <div><el-input class="input" v-model="modifyTime.group_id"></el-input>family_id</div>
          <div><el-input class="input" v-model="modifyTime.Y"></el-input>年</div>
          <div><el-input class="input" v-model="modifyTime.M"></el-input>月</div>
          <div><el-input class="input" v-model="modifyTime.D"></el-input>日</div>
        </div>
        <el-button type="danger" @click="ModifyTap">Modify</el-button>
      </div>
      <el-dialog
        title="提示"
        :visible.sync="modifyVisible"
        width="30%"
        :before-close="handleClose">
        <span>Are you sure to change the time to</span>
        <span>国内时间：{{changeTime}}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="ModifyTap">No</el-button>
          <el-button type="primary" @click="ModifyTapReq">Yes</el-button>
        </span>
      </el-dialog>
      <div class="list">
        <div class="loginlist">
          <div class="head">
            <h4>Behavior Data</h4>
            <el-button class="btn-export" type="text" icon="el-icon-download" @click="exportClick1">Export</el-button>
          </div>
          <div>
            <el-button slot="append" class="btn-ranges" @click="userRangeClick">{{userRangeText}}<i class="el-icon-caret-bottom"></i></el-button>
            <dataFilter
              v-for="(item,index) in userFilterText"
              :key="index"
              :item-data="item"
              :item-index="index"
              open-type="edit"
              :keyList="userFilter"
              @delete="userListFilterDel"
              @apply="apply1"
            >
            </dataFilter>
            <dataFilter :item-data="userItem" :keyList="userFilter" open-type="add" @apply="apply1"></dataFilter>
          </div>
          <div style="position:relative;">
            <datePicker :visable.sync="userPickerVisible" @applydate="applyDate"></datePicker>
          </div>
          <el-table
            style="width: 100%"
            height="400"
            :header-cell-style="{ background: '#F5F5F5' }"
            :data="loginList"
            :default-sort = "{prop: 'time',order: 'ascending'}"
            @sort-change="changeSort1"
            >
            <el-table-column
              prop="time"
              label="Log in time"
              sortable="custom"
              width="180px">
            </el-table-column>
            <el-table-column
              prop="group_id"
              label="Family_id"
              width="160px">
            </el-table-column>
            <el-table-column
              prop="group_name"
              label="Family Name"
              width="120px">
            </el-table-column>
            <el-table-column
              prop="from"
              label="From">
            </el-table-column>
            <el-table-column
              prop="system"
              label="System">
            </el-table-column>
            <el-table-column
              prop="app_version"
              label="App_Version"
              width="120px">
            </el-table-column>
          </el-table>
          <pagination :onlineList="tableData1" @changepage="changePage1" @changesize="changeSize1"></pagination>
        </div>
        <div class="familylist">
          <h4>Family Record</h4>
          <el-table
            style="width: 100%"
            height="400"
            :header-cell-style="{ background: '#F5F5F5' }"
            :data="userList.group_list"
            >
            <el-table-column
              prop="num"
              label="Num"
              width="60px">
            </el-table-column>
            <el-table-column
              prop="group_name"
              label="Family Name"
              width="120px">
            </el-table-column>
            <el-table-column
              prop="group_id"
              label="Family_id"
              width="120px">
              <template slot-scope="scope">
                <div :style="{ 'text-decoration': 'underline' }" @click="openDetails(scope.row.group_id)">{{ scope.row.group_id }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="nickname"
              label="Nick Name"
              width="120px">
            </el-table-column>
            <el-table-column
              prop="group_user_id"
              label="Group_User_id"
              width="200px">
            </el-table-column>
            <el-table-column
              prop="join_time"
              label="Join Time"
              width="180px">
            </el-table-column>
            <el-table-column
              prop="quit_time"
              label="Leave Time"
              width="180px">
            </el-table-column>
            <el-table-column
              prop="create_task_num"
              label="Create_Event_num"
              width="150px">
            </el-table-column>
            <el-table-column
              prop="participate_task_num"
              label="Participate_Event_num"
              width="180px">
            </el-table-column>
          </el-table>
        </div>
        <div class="tasklist">
          <div class="head">
            <h4>Event</h4>
            <el-button class="btn-export" type="text" icon="el-icon-download" @click="exportClick2">Export</el-button>
          </div>
          <div>
            <el-button slot="append" class="btn-ranges" @click="eventRangeClick">{{eventRangeText}}<i class="el-icon-caret-bottom"></i></el-button>
            <dataFilter
              v-for="(item,index) in eventFilterText"
              :key="index"
              :item-data="item"
              :item-index="index"
              open-type="edit"
              :keyList="userFilter2"
              @delete="eventListFilterDel"
              @apply="apply2"
            >
            </dataFilter>
            <dataFilter :item-data="eventItem" :keyList="userFilter2" open-type="add" @apply="apply2"></dataFilter>
          </div>
          <div style="position:relative;">
            <datePicker :visable.sync="eventPickerVisible" @applydate="applyDate"></datePicker>
          </div>
          <el-table
            style="width: 100%"
            height="400"
            :header-cell-style="{ background: '#F5F5F5' }"
            :data="taskList"
            :default-sort = "{prop: 'create_time', order: 'ascending'}"
            @sort-change="changeSort2"
            >
            <el-table-column
              prop="group_id"
              label="Family_id"
              width="160px">
            </el-table-column>
            <el-table-column
              prop="task_id"
              label="Event_id"
              width="160px">
            </el-table-column>
            <el-table-column
              sortable="custom"
              prop="create_time"
              label="Create_Time"
              width="180px">
            </el-table-column>
            <el-table-column
              prop="creator"
              label="Creator"
              width="120px">
            </el-table-column>
            <el-table-column
              prop="name"
              label="Event_Title"
              width="120px">
            </el-table-column>
            <el-table-column
              prop="start_time"
              label="Event_Start"
              width="180px">
            </el-table-column>
            <el-table-column
              prop="end_time"
              label="Event_End"
              width="180px">
            </el-table-column>
            <el-table-column
              prop="uids"
              label="Participates"
              width="120px">
            </el-table-column>
            <el-table-column
              prop="visible_uids"
              label="Visible by"
              width="120px">
            </el-table-column>
            <el-table-column
              prop="remind"
              label="Reminder"
              width="120">
            </el-table-column>
            <el-table-column
              prop="repeat"
              label="Recurring"
              width="120px">
            </el-table-column>
            <el-table-column
              prop="status"
              label="Status">
            </el-table-column>
            <el-table-column
              prop="note"
              label="Note"
              width="160">
            </el-table-column>
          </el-table>
          <pagination :onlineList="tableData2" @changepage="changePage2" @changesize="changeSize2"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/cubbily/DatePicker';
import DataFilter from '@/components/cubbily/DataFilter';
import Pagination from '@/components/cubbily/Pagination';
import {searchAll, userList, userLoginList, userTaskList, exportReq, modifyVip} from '@/api/cubbily.js';
import moment from 'moment';

export default {
  name: 'UserInfo',
  data () {
    return {
      searchValue: '',
      userId: '',
      userList: {},
      loginList: [],
      userRangeText: 'Last 90 days',
      userFilterText: [],
      userStartTime: '',
      userEndTime: '',
      userItem: {
        keys: '1'
      },
      tableData1: {
        pageSize: 30,
        pageNum: 1,
        total: 10,
        order: '',
        sort: ''
      },
      notifyType: ['', 'mins', 'hours', 'days'],
      notifyType2: ['No', 'n minutes before', 'n hours before', 'n days before'],
      taskList: [],
      eventRangeText: 'Last 90 days',
      eventFilterText: [],
      eventStartTime: '',
      eventEndTime: '',
      eventItem: {
        keys: '1'
      },
      tableData2: {
        pageSize: 30,
        pageNum: 1,
        total: 10,
        order: '',
        sort: ''
      },
      userPickerVisible: false,
      eventPickerVisible: false,
      loginVisible: false,
      taskVisible: false,
      keyFilter: '',
      dataParams: {},
      filterParams: {},
      eventFilterParams: {},

      userFilter: [
        {
          value: 'group_id',
          label: 'Family_id'
        },
        {
          value: 'from',
          label: 'From_user'
        },
        {
          value: 'system',
          label: 'System'
        },
        {
          value: 'app_version',
          label: 'App_Version'
        }
      ],
      userFilter2: [
        {
          value: 'group_id',
          label: 'Family_id'
        },
        {
          value: 'task_id',
          label: 'Event_id'
        },
        {
          value: 'create_start',
          label: 'Event_Start'
        },
        {
          value: 'create_end',
          label: 'Event_End'
        },
        {
          value: 'reminder_1',
          label: 'Reminder1'
        },
        {
          value: 'reminder_2',
          label: 'Reminder2'
        },
        {
          value: 'recurring',
          label: 'Recurring'
        },
        {
          value: 'status',
          label: 'Status'
        },
        {
          value: 'note',
          label: 'Note'
        }
      ],
      operatorList: [
        {'=': 1 }, {'≠': 2 }, {'>': 3 }, {'<': 4 }, {'is one of': 5 }, {'exists': 6 }
      ],
      recurringList: ['No', 'Every Day', 'Every Week', 'Every Month', 'Every Year', 'Every other Day', 'Every other Week', 'Every other Month', 'Every other Year'],
      modifyTime: { Y: '', M: '', D: '', group_id: ''},
      changeTime: '',
      ugcTime: '',
      modifyVisible: false
    };
  },
  components: {
    datePicker: DatePicker,
    dataFilter: DataFilter,
    pagination: Pagination
  },
  methods: {
    // 拿到子组件回传的参数
    apply1 (data) {
      if (data.isAddFilterKey) {
        this.userFilterText.push(data);
        console.log('add', this.userFilterText);
      } else {
        this.userFilterText.forEach((item) => {
          if (item.key == data.key) {
            item.operator = data.operator;
            item.value = data.value;
          }
        });
        console.log('reset', this.userFilterText);
      }

      let op = '', val = data.value;
      this.operatorList.forEach((item) => {
        if (Object.keys(item) == data.operator) {
          op = item[data.operator];
        }
      });
      let reqData = Object.assign(this.filterParams, {
        [data.key]: data.operator != 'exists' ? `${op} ${val}` : `${op}`
      });
      this.tableData1.pageNum = 1;
      this.getLoginList(reqData);
    },
    apply2 (data) {
      if (data.isAddFilterKey) {
        this.eventFilterText.push(data);
        console.log('add', this.eventFilterText);
      } else {
        this.eventFilterText.forEach((item) => {
          if (item.key == data.key) {
            item.operator = data.operator;
            item.value = data.value;
          }
        });
        console.log('reset', this.eventFilterText);
      }

      let op = '', val = data.value;
      this.operatorList.forEach((item) => {
        if (Object.keys(item) == data.operator) {
          op = item[data.operator];
        }
      });
      if (data.key == 'recurring' && this.recurringList.includes(val)) {val = this.recurringList.indexOf(val) + 1;}
      if (data.key == 'status') {val = val == 'Deleted' ? 2 : 1;}

      let req = {};
      if (data.keyText == 'Reminder1' || data.keyText == 'Reminder2') {
        let notify_type = ['%', '%'], notify_before = ['%', '%'];
        if (this.eventFilterParams.notify_type) {
          notify_type = this.eventFilterParams.notify_type.split(' ')[1];
          notify_before = this.eventFilterParams.notify_before.split(' ')[1];
          if (notify_type.indexOf(',') != -1) {
            notify_type = notify_type.split(',');
            notify_before = notify_before.split(',');
          } else {
            notify_type = notify_type.split('');
            notify_before = notify_before.split('');
          }
        }
        let isOne = data.keyText == 'Reminder1';
        let typeIndex = this.notifyType2.indexOf(data.value);
        if (isOne) {
          notify_type[0] = typeIndex > -1 ? typeIndex : 1;
          notify_before[0] = data.number || 0;
          if (notify_type[1] == '%' && notify_before[1] == '%') {
            notify_type = notify_type.toString().replace(/,/g, '');
            notify_before = notify_before.toString().replace(/,/g, '');
          }
        } else {
          notify_type[1] = typeIndex > -1 ? typeIndex : 1;
          notify_before[1] = data.number || 0;
          notify_type = notify_type.toString();
          notify_before = notify_before.toString();
        }
        let type, before;
        if (data.operator == 'exists') {
          type = `${op} ${isOne ? 1 : 2}`;
          before = `${op} ${isOne ? 1 : 2}`;
        } else if (data.operator != 'exists') {
          let isNo = data.operator == '=' && data.val != 'No';
          type = isNo ? `7 ${isOne ? 1 : 2}` : `${op} ${notify_type}`;
          before = isNo ? `7 ${isOne ? 1 : 2}` : `${op} ${notify_before}`;
        }
        req = {
          'notify_type': type,
          'notify_before': before
        };
      } else {
        req = {
          [data.key]: data.operator != 'exists' ? `${op} ${val}` : `${op}`
        };
      }
      this.tableData2.pageNum = 1;
      let reqData = Object.assign(this.eventFilterParams, req);
      this.getTaskList(reqData);
      // let reqData = Object.assign(this.eventFilterParams, {
      //   [data.key]: data.operator != 'exists' ? `${op} ${val}` : `${op}`
      // });
      // this.getTaskList(reqData);
    },

    // add filter 删除某个条件
    userListFilterDel (i) {
      let key = this.userFilterText[i].key;
      let obj = this.filterParams;
      delete (obj[key]);
      this.userFilterText.splice(i, 1);
      this.tableData1.pageNum = 1;
      this.getLoginList(this.filterParams);
    },
    eventListFilterDel (i) {
      let key = this.eventFilterText[i].key;
      let obj = this.eventFilterParams;
      let notReminder = true;
      this.eventFilterText.splice(i, 1);
      if (key == 'reminder_1' || key == 'reminder_2') {
        let op1 = obj.notify_type.split(' ')[0];
        let op2 = obj.notify_before.split(' ')[0];
        let notify_type = obj.notify_type.split(' ')[1];
        let notify_before = obj.notify_before.split(' ')[1];
        if (notify_type.indexOf(',') != -1) {
          notify_type = notify_type.split(',');
          notify_before = notify_before.split(',');
        } else {
          notify_type = notify_type.split('');
          notify_before = notify_before.split('');
        }
        if (key == 'reminder_1') {
          notify_type[0] = '%';
          notify_before[0] = '%';
          notify_type = notify_type.toString();
          notify_before = notify_before.toString();
        } else {
          notify_type[1] = '%';
          notify_before[1] = '%';
          notify_type = notify_type.toString().replace(/,/g, '');
          notify_before = notify_before.toString().replace(/,/g, '');
        }
        if (this.eventFilterText.length) {
          this.eventFilterText.forEach((item) => {
            if (item.key == 'reminder_1' || item.key == 'reminder_2') {
              notReminder = false;
            }
          });
        }
        if (!this.eventFilterText.length || notReminder) {
          delete obj['notify_type'];
          delete obj['notify_before'];
        } else {
          this.eventFilterParams.notify_type = `${op1} ${notify_type}`;
          this.eventFilterParams.notify_before = `${op2} ${notify_before}`;
        }
      } else {
        delete (obj[key]);
      }
      this.tableData2.pageNum = 1;
      this.getTaskList(this.eventFilterParams);
    },

    getUserList () {
      userList({
        user_id: this.userId
      })
        .then((res) => {
          if (res.result != 1) {
            this.$message.error(res.error_msg);
            return;
          }
          this.userList = res.data;
          // this.userList.register_time = moment(this.userList.register_time).format('YYYY-MM-DD HH:mm:ss');
          let ulist = this.userList;
          let ageNot = ulist.age < 0 || ulist.age > 200;
          let dob = ulist.birth ? `${ulist.birth} ${ageNot ? '' : '(' + ulist.age + ')'}` : 'Not Set';
          this.userList.dob = dob;
          let list = this.userList.group_list;
          if (list.length) {
            for (let i in list) {
              list[i].num = parseInt(i) + 1;
              // list[i].join_time = moment(list[i].join_time).format('YYYY-MM-DD HH:mm:ss');
              // list[i].quit_time = moment(list[i].quit_time).format('YYYY-MM-DD HH:mm:ss');
              for (let key in list[i]) {
                if (list[i][key] == '' || list[i][key] == null) {list[i][key] = '-';}
              }
            }
            this.userList.group_list = list;
          }
        })
        .catch((error) => {
          console.log('getUserList：', error);
          this.$message.error(error.error_msg);
        });
    },
    getLoginList (params) {
      let data = Object.assign({
        user_id: this.userId,
        start_time: this.userStartTime,
        end_time: this.userEndTime,
        order: this.tableData1.order,
        sort: this.tableData1.sort,
        page: this.tableData1.pageNum,
        limit: this.tableData1.pageSize
      }, params);
      console.log('登录列表data:', data);
      userLoginList(data)
        .then((res) => {
          if (res.result != 1) {
            this.$message.error(res.error_msg);
            return;
          }
          if (params && params.start_time) {this.userStartTime = params.start_time;}
          let list = res.data.login_list;
          // list.forEach((item) => {
          //   item.time = moment(item.time).format('YYYY-MM-DD HH:mm:ss');
          // });
          this.loginList = list;
          this.tableData1.total = res.data.total;
          this.exportReqData1 = Object.assign(data, {limit: res.data.total});
        })
        .catch((error) => {
          console.log('getLoginList：', error);
          this.$message.error(error.error_msg);
        });
    },
    getTaskList (params) {
      let data = Object.assign({
        user_id: this.userId,
        start_time: this.eventStartTime,
        end_time: this.eventEndTime,
        order: this.tableData2.order,
        sort: this.tableData2.sort,
        page: this.tableData2.pageNum,
        limit: this.tableData2.pageSize
      }, params);
      console.log('日程列表data:', data);
      userTaskList(data)
        .then((res) => {
          if (res.result != 1) {
            this.$message.error(res.error_msg);
            return;
          }
          let list = res.data.task_list;
          list.forEach((item) => {
            item.uids = item.uids.split('|').join(',') || '-';
            item.visible_uids = item.visible_uids.split('|').join(',') || '-';
            // item.notify_type = item.notify_type.split(',');
            // item.notify_before = item.notify_before.split(',');
            // let text = [];
            // if (item.notify_type.length) {
            //   for (let i in item.notify_type) {
            //     let str = '';
            //     if (item.notify_before[i] == 0) {
            //       str = item.notify_type[i] == 0 ? 'No' : 'At the time of event';
            //     } else {
            //       let after = this.notifyType[item.notify_type[i]];
            //       str = `${item.notify_before[i]} ${after} before`;
            //     }
            //     text.push(str);
            //   }
            //   item.reminder1 = text[0] || 'No';
            //   item.reminder2 = text[1] || 'No';
            // } else {
            //   item.reminder1 = 'No';
            //   item.reminder2 = 'No';
            // }
            // item.create_time = moment(item.create_time).format('YYYY-MM-DD HH:mm:ss');
            // item.start_time = moment(item.start_time).format('YYYY-MM-DD HH:mm:ss');
            // item.end_time = moment(item.end_time).format('YYYY-MM-DD HH:mm:ss');
            // let i = this.recurringList.indexOf(item.repeat);
            // item.repeat = i > -1 ? this.recurringList[i] : '-';

            item.status = item.status == 2 ? 'Deleted' : '-';
            item.note = item.note ? item.note : '-';
          });
          this.taskList = list;
          this.tableData2.total = res.data.total;
          this.exportReqData2 = Object.assign(data, {limit: res.data.total});
        })
        .catch((error) => {
          console.log('getTaskList：', error);
          this.$message.error(error.error_msg);
        });
    },

    // time ranger点击
    userRangeClick () {
      this.userPickerVisible = !this.userPickerVisible;
      this.dateFilter = 'userFilter';
    },
    eventRangeClick () {
      this.eventPickerVisible = !this.eventPickerVisible;
      this.dateFilter = 'userFilter2';
    },

    // add filter点击
    userListFilter (item) {
      if (item) {
        let itemData = item.split('  ');
        this.userItem = {
          keys: itemData[0],
          operator: itemData[1],
          values: itemData[2]
        };
      } else {
        this.userItem = {
          keys: '',
          operator: '',
          values: ''
        };
      }
      this.loginVisible = !this.loginVisible;
      this.keyFilter = 'userFilter';
      if (this.loginVisible) {
        this.$refs['userFilter'].openModal(this.userItem);
      }
    },
    taskListFilter (item) {
      if (item) {
        let itemData = item.split('  ');
        this.eventItem = {
          keys: itemData[0],
          operator: itemData[1],
          values: itemData[2]
        };
      } else {
        this.eventItem = {
          keys: '',
          operator: '',
          values: ''
        };
      }
      this.taskVisible = !this.taskVisible;
      this.keyFilter = 'userFilter2';
      if (this.taskVisible) {
        this.$refs['userFilter2'].openModal(this.eventItem);
      }
    },

    // apply点击
    applyFilter (data) {
      console.log('父组件applyFilter：', data);
      switch (this.keyFilter) {
      case 'userFilter':
        this.userFilterText = data.filterValue;
        this.getLoginList(Object.assign(this.dataParams, data.params));
        break;
      case 'userFilter2':
        this.eventFilterText = data.filterValue;
        break;
      default:
        break;
      }
      this.filterParams = data.params;
    },
    applyDate (data) {
      console.log('父组件applyDate：', data);
      switch (this.dateFilter) {
      case 'userFilter':
        if (data.text) {this.userRangeText = data.text;}
        this.getLoginList(Object.assign(this.filterParams, data.params));
        break;
      case 'userFilter2':
        if (data.text) {this.eventRangeText = data.text;}
        this.getTaskList(Object.assign(this.eventFilterParams, data.params));
        break;
      }
      this.dataParams = data.params;
    },

    exportClick1 () {
      exportReq({
        url: '/v2/user/login_export',
        fileName: 'behaviorData',
        data: {
          ...this.exportReqData1
        }
      });
    },
    exportClick2 () {
      exportReq({
        url: '/v2/user/task_export',
        fileName: 'userEvent',
        data: {
          ...this.exportReqData2
        }
      });
    },
    changeSize1 (size) {
      if (size) {
        this.tableData1.pageSize = size;
        this.tableData1.pageNum = 1;
        this.getLoginList(this.filterParams);
      }
    },
    changePage1 (current) {
      if (current) {
        this.tableData1.pageNum = current;
        this.getLoginList(this.filterParams);
      }
    },
    changeSize2 (size) {
      if (size) {
        this.tableData2.pageSize = size;
        this.tableData2.pageNum = 1;
        this.getTaskList(this.eventFilterParams);
      }
    },
    changePage2 (current) {
      if (current) {
        this.tableData2.pageNum = current;
        this.getTaskList(this.eventFilterParams);
      }
    },
    openDetails (row) {
      console.log(this.$route, '----当前页面的url信息----');
      console.log(row);
      searchAll({
        platform_id: row
      })
        .then((res) => {
          if (res.result != 1) {
            this.$message.error('Not found');
            return;
          }
          let data = res.data;
          this.$router.push({
            name: 'FamilyInfo',
            params: { searchId: data.id }
          });
        })
        .catch((error) => {
          console.log('searchAll：', error);
          this.$message.error(error.error_msg);
        });
    },

    // 排序的方法
    changeSort1 (data) {
      console.log(data); // column: {…} order: "ascending" prop: "date"
      // 支持参数login_ts登陆时间
      let prop = data.prop == 'time' ? 'login_ts' : data.prop;
      let sort = data.order == 'ascending' ? 1 : 0;
      this.tableData1.pageNum = 1;
      this.tableData1.order = prop;
      this.tableData1.sort = sort;
      this.getLoginList({});
    },
    changeSort2 (data) {
      console.log(data); // column: {…} order: "ascending" prop: "date"
      // 支持传参create_time日程创建时间
      let prop = data.prop;
      let sort = data.order == 'ascending' ? 1 : 0;
      this.tableData2.pageNum = 1;
      this.tableData2.order = prop;
      this.tableData2.sort = sort;
      this.getTaskList({});
    },

    ModifyTap () {
      let modifyTime = this.modifyTime.Y && this.modifyTime.M && this.modifyTime.D && this.modifyTime.group_id;
      if (!modifyTime) {
        this.$message.error('Please enter a valid time');
        return;
      }
      this.modifyVisible = !this.modifyVisible;
      let date = `${this.modifyTime.Y}/${this.modifyTime.M}/${this.modifyTime.D}`;
      let ugcTime = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Shanghai' });
      this.changeTime = new Date(ugcTime).getFullYear() + '年' + Number(new Date(ugcTime).getMonth() + 1) + '月' + new Date(ugcTime).getDate() + '日';
      this.ugcTime = ugcTime;
    },
    ModifyTapReq () {
      modifyVip({
        user_id: this.userId,
        time: new Date(this.ugcTime).getTime(),
        group_id: this.modifyTime.group_id
      })
        .then((res) => {
          this.$message.error(res.result ? 'success' : 'fail');
          if (res.result) {
            this.modifyVisible = false;
            this.modifyTime = { Y: '', M: '', D: '', group_id: ''};
          }
        })
        .catch((error) => {
          console.log('modifyVip：', error);
          this.$message.error(error.error_msg);
        });
    },

    searchClick () {
      const searchValue = this.searchValue.replace(/\s+/g, '');
      searchAll({
        platform_id: searchValue
      })
        .then((res) => {
          if (res.result != 1) {
            this.$message.error('Not found');
            return;
          }
          let data = res.data;
          if (data.type == 1) {
            this.$router.push({
              name: 'FamilyInfo',
              params: { searchId: data.id }
            });
          } else {
            this.userId = data.id;
            this.getUserInfo();
          }
        })
        .catch((error) => {
          console.log('searchAll：', error);
          this.$message.error(error.error_msg);
        });
    },

    getUserInfo () {
      window.sessionStorage.setItem('cubbilyUserId', this.userId);
      let date = new Date();
      let timenow = new Date().getTime();
      let timeago = date.setTime(date.getTime() - 3600 * 1000 * 24 * 90);
      let eventtimeago = date.setTime(date.getTime() - 3600 * 1000 * 24 * 90);
      this.userStartTime = timeago;
      this.eventStartTime = eventtimeago;
      this.vipStartTime = timeago;
      this.userEndTime = timenow;
      this.eventEndTime = timenow;
      this.vipEndTime = timenow;
      this.userRangeText = 'Last 90 days';
      this.eventRangeText = 'Last 90 days';
      this.filterParams = {};
      this.eventFilterParams = {};

      this.getUserList();
      this.getLoginList();
      this.getTaskList();
    },

    handleClose () {

    }
  },

  beforeCreate () {},
  created () {
    this.userId = this.$route.params.searchId  || window.sessionStorage.getItem('cubbilyUserId');
    this.getUserInfo();
  },
  beforeMount () {},
  mounted () {},
  beforeDestroy () {
  },
  destroyed () {}
};
</script>
<style lang="scss">
.user-container {
  width: calc(100% - 24px);
  // height: calc(100vh - 50px - 12px - 24px);
  padding: 22px;
  box-sizing: border-box;
  margin: 12px;
  background: #fff;
  position: relative;
  .search-input{
    width: 480px;
  }
  .flexview{
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    .user-msg{
      width: 220px;
      padding: 24px 12px;
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      margin-right: 22px;
      float: left;
      .avatar{
        display: flex;
        margin-bottom: 30px;
        .photo{
          width: 100%;
          height: 120px;
          line-height: 120px;
          background: #dcdcdc;
          display: block;
          text-align: center;
        }
        h4{
          margin: 14px 0 14px 10px;
          width: 60%;
        }
      }
      div{
        margin-bottom: 15px;
      }
    }
    .list{
      width: calc(100% - 220px);
      .loginlist,
      .familylist,
      .tasklist{
        margin-bottom: 30px;
        h4{
          margin: 50px 0 10px;
          float: left;
        }
        .btn-export{
          margin: 50px 0 10px;
          padding: 0;
          position: absolute;
          right: 24px;
        }
      }
    }
  }
}
.el-table_1_column_1  {
  font-weight: 500;
  color: #000;
}
.el-table thead {
  color: #000;
}
.el-table th, .el-table td{
  padding: 6px 0;
}
.pagination{
  float: right;
}
.el-table::before{
  height: 0;
}
.btn-ranges{
  margin-bottom: 10px;
}
.btn-filter{
  text-decoration:underline
}
.head{
  display: flex;
}
.modify{
  margin-top: 100px;
}
.modify div{
  margin-bottom: 5px 0 0 0 !important;
}
.modify .input{
  width: 100px;
  text-align: center;
}
</style>
